<template>
    <div class="features-area pt-100 pb-70 bg-f4f6fc">
        <div class="container max-width-1290">
            <div class="section-title">
                <h2>Обери свій напрямок</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cog bg-13c4a1'></i>
                        <h3>Back End development</h3>
                        <p>Розробка бізнес логіки back end та API</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-check-shield bg-6610f2'></i>
                        <h3>QA testing</h3>
                        <p>Перевірка якості продукту за допомогою тестування</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-timer bg-ffb700'></i>
                        <h3>Project Management</h3>
                        <p>Управління проектом та командою</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-badge-check bg-fc3549'></i>
                        <h3>Front End development</h3>
                        <p>Розробка Front End з використанням новітніх технологій</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-lock-open bg-00d280'></i>
                        <h3>DEVops</h3>
                        <p>Побудова середовищ розробки, тестування та запуску проекту. Серверні конфігурації .</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-droplet-half bg-ff612f'></i>
                        <h3>UI/UIX Design</h3>
                        <p>Розробка та дизайн інтерфейу користувача.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmazingFeatures'
}
</script>
