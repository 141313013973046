<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle pageTitle="Websites and Mobile apps Development" pageDesc="Our Services" />
        <Servicedetails />

        <OurLovingClients />
        <FooterStyleOne />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo';
import PageTitle from '../Common/PageTitle';
import Servicedetails from '../SingleService/Servicedetails'
import OurLovingClients from '../Common/OurLovingClients'
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        NavbarStyleTwo,
        PageTitle,
        Servicedetails,

        OurLovingClients,
        FooterStyleOne,
    }
};
</script>
