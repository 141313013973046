<template>
    <div class="services-area bg-right-shape ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-content it-service-content">
                    <div class="content left-content">
                        <div class="icon">
                            <img src="../../assets/img/icon1.png" alt="image">
                        </div>
                        <h2>Web & Mobile Development</h2>
                        <p>{{$t('Веб сайт та мобільний додаток зараз є невід\'ємною частиною бізнесу, а в деяких випадках навіть основним інструментом. Вебсайти та додатки допомагають залучати клієнтів, автомазивути продажі та інші процеси. Загалом це чудовий інстумент залучити більшу аудиторію')}}</p>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="feature-box">
                                    <i class='bx bxs-badge-check'></i>  
                                    Responsive Design
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="feature-box">
                                    <i class='bx bxs-badge-check'></i> 
                                    UI / UX Design
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="feature-box">
                                    <i class='bx bxs-badge-check'></i> 
                                    Mobile App Development
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="feature-box">
                                    <i class='bx bxs-badge-check'></i> 
                                    Web Development
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="feature-box">
                                    <i class='bx bxs-badge-check'></i> 
                                    CMS Development
                                </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                CRM Development
                              </div>
                            </div>
                        </div>

                        <router-link to="/web-service" class="default-btn">
                            <i class="bx bxs-spreadsheet"></i> 
                            {{$t('Детальніше')}}
                            <span></span>
                        </router-link>
                    </div>
                </div>

                <div class="services-image">
                    <div class="image">
                        <img src="../../assets/img/services-image/service1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileDevelopment'
}
</script>
