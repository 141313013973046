<template>
    <div class="features-card-section pt-100 pb-70 bg-f8fbfa">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-card">
                        <i class='bx bx-conversation'></i>
                        <h3>
                            <router-link to="/single-service">IT Consultancy</router-link>
                        </h3>
                        <p>{{$t('Ми надаємо розгоргуту консультацію щодо вашого кейсу, проводимо дослідження та розробляємо інтернет маркетингову стратегію вашого продукту чи бізнесу.')}}</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-card">
                        <i class='bx bx-laptop'></i>
                        <h3>
                            <router-link to="/single-service">Web & App Development</router-link>
                        </h3>
                        <p>{{$t('Ми розробляємо WEB проекти та додатки будь-якої складності відповідно до ваших вимог та вимог вашого бізнесу.')}}</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-card">
                        <i class='bx bxs-badge-check'></i>
                        <h3>
                            <router-link to="/single-service">IT Solutions</router-link>
                        </h3>
                        <p>{{$t('Ми розробляємо системні ІТ рішення для інтеграції сервісів у ваш бізнес чи вашого бізнесу з партнерами.')}}</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-features-card">
                        <i class='bx bxs-megaphone'></i>
                        <h3>
                            <router-link to="/single-service">Digital Marketing</router-link>
                        </h3>
                        <p>{{$t('Ми надаємо супровід всіх наших рішень. Також робимо аналіз та майбутні оптимізації продуктів і рішень.')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>
