<template>
    <div class="ptb-100 bg-f4f6fc">
        <div class="container">
            <div class="section-title">
                <h2 class="mb-2">Що дає Boot Camp</h2>
                <p>Boot Camp дає однаково багато як для розробників без досвіду так і для досвідчених розробників. </p>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-left-img">
                            <img src="../../assets/img/home-saas/feature2.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">01</span>
                            <h3>Робота в команді над реальним проектом</h3>
                            <p>Ми будемо розробляти реальний проект, який по завершенню запустимо і він буде працювати. Ви зможете вказувати це в резюме як ваш досвід роботи.</p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Робота в команді
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Використання сучасних технологій
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Проходить онлайн, ви самі організовуєте свій час
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Фітбек та підтримка
                                </li>
                            </ul>
                            <a href="https://forms.gle/9u4mJKnyguAEpRP78" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Подати заявку
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">02</span>
                            <h3>Ролі які ви можете обрати</h3>
                            <p>Кожен учасник є однаково цінним для команди і проекту. Ви можете обрати ту роль яка вам найбільше подобається і в якій ви зможете накраще себе проявити чи в якій давно хотіли себе спробувати.</p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    UI/UIX Designer
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Front End developer (Vue.js, Angular, React )
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Back-end developer ( PHP)
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Solution Architect ( AWS )
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    DevOps Engineer
                                </li>
                              <li>
                                <i class='bx bx-badge-check'></i>
                                QA Engineer
                              </li>
                              <li>
                                <i class='bx bx-badge-check'></i>
                                Project Manager
                              </li>
                              <li>
                                <i class='bx bx-badge-check'></i>
                                Scrum Master
                              </li>
                            </ul>
                            <a href="https://forms.gle/9u4mJKnyguAEpRP78" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Подати Заявку
                                <span></span>
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-right-img">
                            <img src="../../assets/img/home-saas/feature3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-item">
            <div class="container max-width-1290">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="overview-left-img">
                            <img src="../../assets/img/home-saas/feature4.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="overview-content pl-3">
                            <span class="number">03</span>
                            <h3>Повний цикл розробки</h3>
                            <p>Ми пройдемо повний цикл розробки, від складання технічного завдання, проектуваання архітектури проекту до його тестування, запуску та налагодження майбутніх оновлень.
                              Це не імітація реальної розробки - це і є реальна робота над реальним non profit проектом. Ми повністю пройдемо наступні етапи:
                            </p>
                            <ul>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Аналіз завдання "клєнта" та складання технічного завдання
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Організація команди, складання Road Map роботи.
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Складання архітектури проекту для втілення всіх необхідних функцій, а також досягнення максимальної ефективності
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Підготовка до розробки та розробка з використанням всіх необхідних інструментів та технологій
                                </li>
                                <li>
                                    <i class='bx bx-badge-check'></i>
                                    Тестування та запуск проекту. Організаця future support та оновлень.
                                </li>
                            </ul>
                            <a href="https://forms.gle/9u4mJKnyguAEpRP78" class="default-btn black-btn">
                                <i class='bx bxs-arrow-to-right'></i>
                                Подати заявку
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MoreToDiscover'
}
</script>
