<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <router-link to="/"><img src="../../assets/img/logo.png" alt="image"></router-link>
                        </div>
                        <p>{{$t('Якість продукту та послуг що ми надаємо для нас на першому місці.')}}</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Company</h3>

                        <ul class="services-list">
                            <li><router-link to="/about">{{ $t('Про нас')}}</router-link></li>
                            <li> <router-link to="/web-service" class="nav-link">Web Services</router-link></li>
                            <li><router-link to="/cloud-service" class="nav-link">Cloud Services</router-link></li>
                            <li><router-link to="/pricing" class="nav-link">{{$t('Ціни')}}</router-link></li>
<!--                            <li><router-link to="/blog-one">Latest News</router-link></li>-->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Support</h3>

                        <ul class="support-list">
                            <li><router-link to="/faq">FAQ's</router-link></li>
                            <li><router-link to="/team" class="nav-link">Team</router-link></li>
<!--                            <li><router-link to="/single-blog">Blog Details</router-link></li>-->
                            <li><router-link to="/about" class="nav-link">{{$t('Про нас')}}</router-link></li>
                            <li><router-link to="/contact" class="nav-link">{{ $t('Контакти') }}</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>

                        <ul class="footer-contact-info">
                            <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">Zhitomir, Ukraine</a> / Cody, Wyoming, USA / Leeds, UK </li>
                            <li>Email: <a href="mailto:info@artofitsolution.com">info@artofitsolution.com</a></li>
<!--                            <li>Phone: <a href="tel:+321754754">+0 (321) 984 754</a></li>-->
                        </ul>
                        <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <p>© <a href="http://artofitsolution.com" target="_blank">artofitsolution.com</a> All rights reserved</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}
</script>
