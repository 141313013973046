import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import VueGtag from "vue-gtag";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "./assets/custom.scss";
import {createI18n} from "vue-i18n";
import uk from './locales/uk.js'
import en from './locales/en.js'

const app = createApp(App);
const i18n = createI18n({
    locales: [
        { code:  'uk',
            name: 'UA'
        },
        { code: 'en',
            name: 'EN'
        }
    ],
    locale: 'en',
    messages: {
        en: en,
        uk: uk
    }

    // something vue-i18n options here ...
})
app.use(i18n)
app.use(VueGtag, {
    config: { id: "G-4GH9V0CWQ4" }
})
app.use(BootstrapVue3).use(router).mount("#app");

