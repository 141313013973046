<template>
    <div class="services-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="services-image">
                    <div class="image">
                        <img src="../../assets/img/home-saas/feature1.png" alt="image">
                    </div>
                </div>

                <div class="services-content it-service-content">
                    <div class="content">
                        <div class="features-inner-content">
                            <div class="features-item">
                                <i class='bx bx-phone-call bg-13c4a1 blt-radius-0'></i>
                                <h3>Коли та Мітинги</h3>
                                <p>Регулярні зустрічі онлайн, звязок з усією командою, планування обговорення та консультації будь-яких питань в межах проекту</p>
                            </div>

                            <div class="features-item">
                                <i class='bx bx-gift bg-6610f2 blt-radius-0'></i>
                                <h3>Досвід на реальному проекті</h3>
                                <p>Отримайте досвід роботи на реальному проекті в складі команди. Це чудовий шанс старту в IT а також розвиток для тих хто вже працює.</p>
                            </div>

                            <div class="features-item">
                                <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                                <h3>Latest Tech Stack and Code Review</h3>
                                <p>Використаємо на проекті останні тахнології, найкращі паттерни. Будемо проводити регулярні Code Review. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape9">
            <img src="../../assets/img/shape/9.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>
