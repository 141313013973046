<template>
    <section class="contact-area ptb-100">
        <div class="container">
            <div class="contact-inner">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="contact-features-list">
                            <h3>Some other great reasons to choose us</h3>
                            <p>Take the first steps towards increased productivity and reduced stress with ArtOfItSolution.</p>
                            <p>We are a highly trained, dedicated team, helping entrepreneurs, professionals, and small teams streamline success - not just in work, but in life too. We help spare your time to focus on the bigger picture by taking care of the little details.</p>

                            <ul>
                                <li><i class='bx bx-badge-check'></i> Staff works from our offices in Europe and USA</li>
                                <li><i class='bx bx-badge-check'></i> Flexible solutions on demand</li>
                                <li><i class='bx bx-badge-check'></i> One time or on going projects</li>
                                <li><i class='bx bx-badge-check'></i> Provide full service</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="contact-form">
                            <h3>Send us your questions</h3>

                            <form @submit.prevent>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="name" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" v-model="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>



                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" v-model="subject" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" v-model="text" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn" @click="send_request()"><i class='bx bxs-paper-plane' ></i>Send Message +<span></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="contact-info">
                    <div class="contact-info-content">
                        <h3>Contact us by Email Address</h3>
                        <h2>
<!--                            <a href="tel:+0881306298615">+088 130 629 8615</a>
                            <span>OR</span>-->
                            <a href="mailto:hello@striki.com">info@artofitsolution.com</a>
                        </h2>

                        <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";

export default {
    name: 'Contact',
    data(){
      return {
        subject:'',
        text:'',
        email:'',
        name: '',
      }
    },
    methods: {
      send_request: function (){
        console.log('SEnd message test')
        this.send_email();

      },
      send_email(){
        var email = {

            "subject": "ArtOfItSolutions: "+this.subject ,
            "text": "New request from "+this.name + " :::  " + this.text,
            "email": this.email

        }

        axios.post('https://ni2sshyf57.execute-api.eu-north-1.amazonaws.com/artOfAolutionEmailSender', {email},{withCredentials: false}).then(
            (response) => {
              console.log(response);
            }
        )
      }
    }
}
</script>
