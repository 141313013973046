<template>
    <div class="service-details ptb-100">
        <div class="container">
            <div class="service-details-image">
                <img src="../../assets/img/services-image/cloud_service.jpg" alt="image">
            </div>

            <div class="service-details-content">
                <h3>{{$t('Хмарні рішення для стартапів та бізнесу')}}</h3>
    
                <p>{{$t('Все більшої популярності набувають хмарні рішення для стартапів та бізнесу. І тому є причина - швидкий старт, гнучкість рішень, лагкість обслуговування та безпека.')}}</p>

                <h4>{{$t('Швидкий та легкий старт')}}</h4>
                <p>{{$t('За допомогою хмарних рішень можливо вийти з базовим сайтом в онлайн менш ніж за годину. Цей варіант ідеальний для стартапів. Ви матимете змогу займатися бізнес процесами замість налагодження ІТ інфраструктури. Хмарні рішення дозволяють планувати запуск бізнес рішень за вашим графіком.')}} </p>
                <p>{{$t('Також ви отримуєте дуже потужні інструменти для бізнесу які б не змогли собі доволити на ранніх етапах.')}}</p>

                <h4>{{$t('Гнучкість рішень')}}</h4>
                <p>{{$t('З хмарними рішеннями немає потреби чітко прогнозувати масштаби ІТ ресурсів. Завжди є можливість збільшити чи зменшити потужності в залежності від поточної ситуації в компанії чи на ринку. Навіть є можливість мати динамічно змінну потужність що оптимізує затрати на ІТ.')}}</p>


<!--                <div class="service-details-info">
                    <div class="single-info-box">
                        <h4>Client</h4>
                        <span>James Anderson</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Category</h4>
                        <span>Saas, Marketing</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Date</h4>
                        <span>February 28, 2022</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Share</h4>
                        <ul class="social">
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="single-info-box">
                        <a class="default-btn" href="#">Live Preview</a>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CloudServicedetails'
}
</script>
