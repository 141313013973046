<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
        <MobileDevelopment />
        <HostingServices />
        <WhyChooseUs />
        <AmazingFeatures />
        <WhatsOurClients />
        <PricingPlan />
        <ReadyToHelpYou />
        <Partner />
        <OurLovingClients />
        <Faq />
<!--        <BestSupport />
        <LatestNews />-->
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar';
import MainBanner from '../Home/MainBanner';
import Features from '../Common/Features';
import MobileDevelopment from '../Home/MobileDevelopment';
import HostingServices from '../Home/HostingServices';
import WhyChooseUs from '../Common/WhyChooseUs';
import AmazingFeatures from '../Home/AmazingFeatures';
import WhatsOurClients from '../HomeTwo/WhatsOurClients';
import PricingPlan from '../Common/PricingPlan';
import ReadyToHelpYou from '../Common/ReadyToHelpYou';
import OurLovingClients from '../Common/OurLovingClients';
import Faq from '../Common/Faq'
//import BestSupport from '../Home/BestSupport'
//import LatestNews from '../Common/LatestNews'
import Footer from '../Layouts/Footer'
import Partner from "../Common/Partner";

export default {
    name: 'HomePage',
    components: {
      Partner,
        Navbar,
        MainBanner,
        Features,
        MobileDevelopment,
        HostingServices,
        WhyChooseUs,
        AmazingFeatures,
        WhatsOurClients,
        PricingPlan,
        ReadyToHelpYou,
        OurLovingClients,
        Faq,
      //  BestSupport,
      //  LatestNews,
        Footer,
    }
};
</script>
