<template>
    <div class="features-area pt-100 pb-70 bg-f4f6fc">
        <div class="container">
            <div class="section-title">
                <h2>{{$t('Наші неймовірні продукти та рішення')}}</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-conversation bg-13c4a1'></i>
                        <h3>IT Consultancy</h3>
                        <p>{{$t('Ми надамо вам розширену консультацію щодо ваших запитів не залежно чи ви маєте вже готовий план чи тільки ідею.')}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-badge-check bg-6610f2'></i>
                        <h3>IT Solutions</h3>
                        <p>{{$t('Ми розробляємо рішення що найкраще підходять для вирішення ваших задач та є ефективними для вашиги бізнесу.')}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-dashboard bg-ffb700'></i>
                        <h3>Simple Dashboard</h3>
                        <p>{{$t('Ми проводимо детальне планування кожного етапу проекту щоб отримати максимальну ефективність процесу розробки')}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-bell-ring bg-fc3549'></i>
                        <h3>Deadline Reminders</h3>
                        <p>{{$t('Весь процес розробки є прозорий та зрозумілий. Ви завжди будете знати стан проекту та терміни виконання.')}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bxs-info-circle bg-00d280'></i>
                        <h3>Information Retrieval</h3>
                        <p>{{$t('Ви можете отримати розгорнуту інформацію що вас цікавить щодо кожного етапу розробки та технологій загалом.')}}</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="features-box-one">
                        <i class='bx bx-cog bg-ff612f'></i>
                        <h3>Flexible Functionality</h3>
                        <p>{{$t('Гнучкість рішень - це те на що ми орієнтуємося. Ми вріховуємо всі можливі зміни робочих навантажень щоб отримати стабільну систему')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AmazingFeatures'
}
</script>
