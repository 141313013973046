export default {
    'Головна' : 'Home',
    'На головну':'Go Home',
    'Про нас': 'About',
    'Ціни' : 'Price',
    'Команда': 'Team',
    'Контакти': 'Contact',
    'До справи':'Get started',
    'IT Рішення для Стартапів та Бізнесу' : 'IT Solutions for Startups and Business',
    'Ми володіємо мистецтвом сворювати ІТ рішення для стартапів та бізнесу.Виведемо вас на новий рівень в ІТ.':'We have the art of making IT solutions for startups and business. We will bring you to a new level in IT.',
    'Ми надаємо розгоргуту консультацію щодо вашого кейсу, проводимо дослідження та розробляємо інтернет маркетингову стратегію вашого продукту чи бізнесу.':'We provide detailed advice on your case, conduct research and develop an online marketing strategy for your product or business.',
    'Ми розробляємо WEB проекти та додатки будь-якої складності відповідно до ваших вимог та вимог вашого бізнесу.':'We develop WEB projects and applications of any complexity according to your requirements and the requirements of your business.',
    'Ми розробляємо системні ІТ рішення для інтеграції сервісів у ваш бізнес чи вашого бізнесу з партнерами.' : 'We develop system IT solutions to integrate services into your business or your business with partners.',
    'Ми надаємо супровід всіх наших рішень. Також робимо аналіз та майбутні оптимізації продуктів і рішень.':'We provide support for all our solutions. We also do analysis and future optimizations of products and solutions.',
    'Веб сайт та мобільний додаток зараз є невід\'ємною частиною бізнесу, а в деяких випадках навіть основним інструментом. Вебсайти та додатки допомагають залучати клієнтів, автомазивути продажі та інші процеси. Загалом це чудовий інстумент залучити більшу аудиторію':'The website and mobile application are now an integral part of the business, and in some cases even the main tool. Websites and applications help attract customers, automate sales and other processes. In general, it is a great way to attract a larger audience',
    'Детальніше':'Read more',
    'Використання хмарних сервісів та безерверних технологій підвищує гнічкість рішень та полегшує підтримку.Ви отримаєте чудовий результат і в коротший термін.':'The use of cloud services and erververless technologies increases the fragility of solutions and facilitates support.You will get a great result in a shorter period of time.',
    'Чому вам варто співпрацювати з нами':'Why you should cooperate with us',
    'Маєте питання?':'Do you have any questions?',
    'Напишіть нам.':'Contact us',
    'Написати':'Contact',
    'Наші неймовірні продукти та рішення':'Our incredible products and solutions',
    'Ми надамо вам розширену консультацію щодо ваших запитів не залежно чи ви маєте вже готовий план чи тільки ідею.':'We will provide you with extended advice on your requests, regardless of whether you already have a plan or just an idea.',
    'Ми розробляємо рішення що найкраще підходять для вирішення ваших задач та є ефективними для вашиги бізнесу.':'We develop solutions that are best suited to solve your problems and are effective for your business.',
    'Ми проводимо детальне планування кожного етапу проекту щоб отримати максимальну ефективність процесу розробки':'We carry out detailed planning of each stage of the project to get maximum efficiency of the development process',
    'Весь процес розробки є прозорий та зрозумілий. Ви завжди будете знати стан проекту та терміни виконання.':'The whole development process is transparent and clear. You will always know the status of the project and the deadlines.',
    'Ви можете отримати розгорнуту інформацію що вас цікавить щодо кожного етапу розробки та технологій загалом.':'You can get detailed information about each stage of development and technology in general.',
    'Гнучкість рішень - це те на що ми орієнтуємося. Ми вріховуємо всі можливі зміни робочих навантажень щоб отримати стабільну систему':'Flexibility of solutions is what we focus on. We take into account all possible workload changes to get a stable system',
    'Що кажуть клієнти про':'What customers say about',
    'Наша цінова політика':'Our pricing',
    'Розробка':'Development',
    'Лендінг':'Landing page',
    'Швидке рішення виходу online':'Fast way to get online',
    'Професійний дизайн':'Professional design',
    'Творчий підхід':'Creative',
    'Email інтеграція':'Email integration',
    'Messenger інтеграція':'Messenger integration',
    'SEO оптимізація':'SEO optimization',
    'Інтеграція рекламних продуктів':'Advertisement integration',
    'Допомога з хостингом':'Hosting assistance',
    'Кастомні рішення під ваш продукт':'Customized solution for your product',
    'Ідеально для стартапів':'Best fit for startups',
    'Google Analytics звіт':'Google Analytics assistance',
    'Інтернет Магазин':'e-commerce',
    'Зручний та зрозумілий інтерфейс':'User-friendly and intuitive interface',
    'Панель адміністратора та менеджера':'Admin and Manager dashboard',
    'Інтеграція будь-яких платіжних систем':'Payment provider integration',
    'Налаштування аналітики':'Analytic assistance',
    'Підключення реклами':'Advertisement integration',
    'Індивідуальні ІТ рішення':'Individual IT solutions',
    'Розробка ІТ рішень для бізнесу':'Develop business IT solutions',
    'Новітні техналогії':'Modern technologies',
    'Проектування на ранньому етапі':'Early Stage Designing',
    'Інтеграція вже існуючих рішень':'Integration of existing solutions',
    'Хмарні рішення':'Cloud solutions',
    'Розробка додатків': 'Mobil app development',
    'Документація та підтримка':'Documentation and support',
    'Професійна команда':'Professional team',
    'Прозорі умови та терміни':'Clear terms',
    'Орієнтація на успіх':'Success oriented',
    'Креативність':'Creativity',
    'Підтримка':'Support',
    'Підтримка хмарних рішень':'Cloud IT support',
    'Оновлення продуктів':'Updates assistance',
    'Оптимізація вартості':'Cost optimization',
    'Оптимізація ресурсів' : 'Resource optimizations',
    'Аудит та покращення безпеки': 'Security audit & improvement',
    'Міграція рішень':'Solutions migration',
    'Сертифіковані спеціалісти':'Certified stuff',
    'Підтримка онлайн':'Online support',
    'Рекомендації щодо нових ІТ рішень':'Recommendations for New IT solutions',
    'Масштабування сервісів':'Scaling services',
    'Бізнес аналітика':'Business analysis',
    'Підтримка кастомних рішень': 'Support for custom solutions',
    'А/Б тести':'A/B tests',
    'Маркетингова стратегії продукту':'Product Marketing strategy',
    'Email розсилки' : 'Email campaigns',
    'Messenger боти' : 'Chat bots',
    'Статистика та розгорнуті звіти': 'Statistics and Expanded Reports',
    'Підтримка життєздатності всіх систем': 'Support for the viability of all systems',
    'Актуалізація систем': 'System Actualization',
    'Підтримка рекламних кампаній':'Support for Advertising Campaigns',
    'Онлайн підтримка':'Online Support',
    'Рішення під ваш продукт':'Solutions for your product',
    'Пітримка сайту':'Website support',
    'Підтримка хостингу':'Hosting assistance',
    'Аудит та підтримка безпеки':'Security audit and improvement',
    'Бекап та відновелення':'Backup and restore',
    'Email підтримка': 'Email support',
    'Підтримка бази даних':'Database support',
    'Актуалізація ПЗ':'Software update',
    'Аналітика роботи сайту':'Website audit',
    'Додаткові севіси':'Additional services',
    'Консультація щодо нових ІТ рішень':'Consultation on New IT solutions',
    'Бізнес підтримка':'Business Support',
    'Знайомтеся з нашою командою':'Meet our team',
    'Наші клієнти:':'Our clients',
    'Якість продукту та послуг що ми надаємо для нас на першому місці.':'The quality of the product and services that we provide is in the first place for us.',
    'Ми розробляємо веб рішення - сайти та додатки':'We develop web solutions - websites and mobile applications',
    'Веб рішення - це найкраший спосіб залучити клієнтів за межами вашого регіону чи навіть по всьому світу. Ви зможете з легкістю залучити нових клієнтів чи аудиторію з будь-якого місця де є інтернет.':'Web solutions are the best way to attract customers outside your region or even around the world. You can easily attract new customers or audiences from anywhere where there is an Internet.',
    'Робзробка сайтів та інтернет магазинів':'Development of sites and ecommerce sites',
    'В наш час сайт компанії який є її визітівкою, розповідає про неї клієнту та пропонує сервіси це необхідність для будь-якого бізнесу. Наша професійна команда може визначити ключові терміни та ефективно представити вашу компанію в інтернеті за допомогою ефективного і персоналізованого вебсайту.':'Nowadays, the website of the company, which is its idea, tells about it to the client and offers services that is a necessity for any business. Our professional team can define key terms and effectively present your company online through an effective and personalized website.',
    'Інтенет магазин - найефективніший вид комерційної діяльності. Якщо ви ще навіть знаходитися на стації ідеї - ми допоможемо вам розробити інтернет маркетингову стратегію та зможемо супровождувати запуск інтернет магазину чи продукту в інтернеті для досягнення максимальної ефективності.':'Nowadays, the website of the company, which is its idea, tells about it to the client and offers services that is a necessity for any business. Our professional team can define key terms and effectively present your company online through an effective and personalized website.',
    'Розробка мобільних додатків':'Mobile application development',
    'Майже 90 відсотків користувачів інтернету використовують мобільні телефони, тому мобільні додатки дуже важливий інструмент січасного бізнесу. Наші досвідчена команда розробників реалізує єфективні додатки з необхідним функціоналом саме вашому бізнесу. Ми використовуємо перевірені та сучасні рішення.':'Almost 90 percent of Internet users use mobile phones, so mobile applications are a very important tool of the current business. Our experienced development team implements effective applications with the necessary functionality for your business. We use proven and modern solutions.',
    'Хмарні рішення для стартапів та бізнесу':'Cloud solutions for startups and businesses',
    'Все більшої популярності набувають хмарні рішення для стартапів та бізнесу. І тому є причина - швидкий старт, гнучкість рішень, лагкість обслуговування та безпека.':'Cloud solutions for startups and businesses are becoming increasingly popular. And there is a reason - quick start, flexibility of solutions, easy maintenance and security.',
    'Швидкий та легкий старт':'Quick and easy start',
    'За допомогою хмарних рішень можливо вийти з базовим сайтом в онлайн менш ніж за годину. Цей варіант ідеальний для стартапів. Ви матимете змогу займатися бізнес процесами замість налагодження ІТ інфраструктури. Хмарні рішення дозволяють планувати запуск бізнес рішень за вашим графіком.':'With the help of cloud solutions, it is possible to go online with the base site in less than an hour. This option is ideal for startups. You will be able to engage in business processes instead of setting up IT infrastructure. Cloud solutions allow you to plan the launch of business solutions according to your schedule.',
    'Також ви отримуєте дуже потужні інструменти для бізнесу які б не змогли собі доволити на ранніх етапах.':'You also get very powerful tools for business that would not be able to satisfy themselves in the early stages.',
    'Гнучкість рішень':'Flexibility of solutions',
    'З хмарними рішеннями немає потреби чітко прогнозувати масштаби ІТ ресурсів. Завжди є можливість збільшити чи зменшити потужності в залежності від поточної ситуації в компанії чи на ринку. Навіть є можливість мати динамічно змінну потужність що оптимізує затрати на ІТ.':'With cloud-based solutions, there is no need to clearly predict the scale of IT resources. There is always an opportunity to increase or decrease capacity depending on the current situation in the company or on the market. It is even possible to have dynamically variable power that optimizes IT costs.',
    'Ми WEB Agency що розробляє комплексні продукти та рішення для бізнесу та стартапів. Ми створюємо сайти, мобільни додатки, комплексні CRM системи та інші рішення для автоматизації, оптимизації та виведення бізнесу в інтернет.':'We are a WEB Agency that develops comprehensive products and solutions for businesses and startups. We create websites, mobile applications, complex CRM systems and other solutions for automation, optimization and bringing business to the Internet.',
    'Я Іван Костюшко, засновник. Вже більше 10 років я працюю на ІТ ринку і створюю рішення для клієнтів. З багатьма стартапами ми пройшли шлях від ідеї і до успіху. За цей час я отримав великий досвід планування та розробки продуктів та роботи з командою.':'I am Ivan Kostiushko, founder. For more than 10 years I have been working in the IT market and creating solutions for customers. With many startups, we have gone from idea to success. During this time, I have gained extensive experience in planning and developing products and working with a team.',
    'Ми працюємо на успіх клієнта і використовуємо сучасні технології що найкраще підходять та будуть найефективніші для бізнес задачі.':'We work for the success of the client and use modern technologies that are best suited and will be the most effective for business tasks.',
    'Напишіть нам і ми з радістю розглянемо ваші задачі.':'Contact us and we will gladly consider your tasks.',
}
