<template>
    <div class="partner-area pt-100 pb-70 bg-f8fbfa">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-12">
                    <div class="partner-title">
                        <h3>Featured by:</h3>
                    </div>
                </div>

                <div class="col-lg-9 col-md-12">
                    <div class="partner-slides">
                        <carousel
                            :autoplay="5000"
                            :settings='settings' 
                            :breakpoints='breakpoints'
                        >
                            <slide 
                                v-for="slide in carouselItems" 
                                :key="slide.id"
                            >
                                <div class="single-partner-item">
                                    <a href="#">
                                        <img :src="slide.image" alt="image">
                                    </a>
                                </div>
                            </slide>

                            <template #addons>
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Partner',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/soft-logo/anG.svg'),
            },
            {
                id: 2,
                image: require('../../assets/img/soft-logo/amazon_aws.svg'),
            },
            {
                id: 3,
                image: require('../../assets/img/soft-logo/android21.svg'),
            },
            {
                id: 4,
                image: require('../../assets/img/soft-logo/digitalocean.svg'),
            },
            {
                id: 5,
                image: require('../../assets/img/soft-logo/figma.svg'),
            },
          {
            id: 6,
            image: require('../../assets/img/soft-logo/git.svg'),
          },
          {
            id: 7,
            image: require('../../assets/img/soft-logo/google_analytics.svg'),
          },
          {
            id: 8,
            image: require('../../assets/img/soft-logo/ionicframework.svg'),
          },
          {
            id: 9,
            image: require('../../assets/img/soft-logo/java.svg'),
          },
          {
            id: 10,
            image: require('../../assets/img/soft-logo/lara.svg'),
          },
          {
            id: 11,
            image: require('../../assets/img/soft-logo/php.svg'),
          },{
            id: 12,
            image: require('../../assets/img/soft-logo/paypal.svg'),
          },{
            id: 13,
            image: require('../../assets/img/soft-logo/reactjs.svg'),
          },{
            id: 14,
            image: require('../../assets/img/soft-logo/stripe.svg'),
          },{
            id: 15,
            image: require('../../assets/img/soft-logo/Symfony2.svg'),
          },
          {
            id: 16,
            image: require('../../assets/img/soft-logo/vuejs.svg'),
          },
          {
            id: 17,
            image: require('../../assets/img/soft-logo/wordpress.svg'),
          },
          {
            id: 18,
            image: require('../../assets/img/soft-logo/yiiframework.svg'),
          },



        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>
