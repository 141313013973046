<template>
    <div class="pricing-area pt-100 pb-70 bg-f4f5fe">
        <div class="container">
            <div class="section-title">
                <h2>{{$t('Наша цінова політика')}}</h2>
            </div>

            <div class="pricing-list-tab">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <i :class="tab.icon"></i>
                            {{$t(tab.name)}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <div class="row justify-content-center">
                                <div 
                                    class="col-lg-4 col-md-6"
                                    v-for="item in tab.items"
                                    :key="item.id"
                                >
                                    <div class="single-pricing-table left-align">
                                        <div class="pricing-header">
                                            <h3>{{$t(item.title)}}</h3>
                                        </div>

                                        <div class="price">
                                            <sup>$</sup>{{item.price}}<sub v-if="item.period !== ''">/ {{item.period}}</sub>
                                        </div>

                                        <ul class="pricing-features">
                                            <li
                                                v-for="feature in item.pricingFeatures"
                                                :key="feature.id"
                                            >
                                                <i :class="feature.icon"></i> 
                                                {{$t(feature.feature)}}
                                            </li>
                                        </ul>

                                        <div class="btn-box">
                                            <router-link :to="item.btnLink" class="default-btn">
                                                <i class="bx bxs-hot"></i> 
                                                {{item.btnText}}
                                                <span></span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PricingPlan',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    icon: 'bx bxs-calendar-check',
                    name: 'Розробка',
                    items: [
                        {
                            id: 1,
                            title: 'Лендінг',
                            price: '400',
                            period: '',
                            btnText: 'Try It Now',
                            btnLink: '/contact',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Швидке рішення виходу online',
                                },
                                {
                                    id: 2,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Професійний дизайн',
                                },
                                {
                                    id: 3,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Творчий підхід',
                                },
                                {
                                    id: 4,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Email інтеграція',
                                },
                                {
                                    id: 5,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Messenger інтеграція',
                                },
                                {
                                    id: 6,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'SEO оптимізація',
                                },
                                {
                                    id: 7,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Інтеграція рекламних продуктів',
                                },
                                {
                                    id: 8,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Допомога з хостингом',
                                },
                                {
                                    id: 9,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Кастомні рішення під ваш продукт',
                                },
                                {
                                    id: 10,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Ідеально для стартапів',
                                },
                                {
                                    id: 11,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Google Analytics звіт',
                                },
                            ]
                        },
                        {
                            id: 2,
                            title: 'Інтернет Магазин',
                            price: '800',
                            period: '',
                            btnText: 'Try It Now',
                            btnLink: '/contact',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Професійний дизайн',
                                },
                                {
                                    id: 2,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'SEO оптимізація',
                                },
                                {
                                    id: 3,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Зручний та зрозумілий інтерфейс',
                                },
                                {
                                    id: 4,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Панель адміністратора та менеджера',
                                },
                                {
                                    id: 5,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Messenger інтеграція',
                                },
                                {
                                    id: 6,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Email інтеграція',
                                },
                                {
                                    id: 7,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Інтеграція будь-яких платіжних систем',
                                },
                                {
                                    id: 8,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Рішення під ваш продукт',
                                },
                                {
                                    id: 9,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Допомога з хостингом',
                                },
                                {
                                    id: 10,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підключення реклами',
                                },
                                {
                                    id: 11,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Налаштування аналітики',
                                },
                            ]
                        },
                        {
                            id: 3,
                            title: 'Індивідуальні ІТ рішення',
                            price: '1000',
                            period: '',
                            btnText: 'Get estimation',
                            btnLink: '/contact',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Розробка ІТ рішень для бізнесу',
                                },
                                {
                                    id: 2,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Новітні техналогії',
                                },
                                {
                                    id: 3,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Проектування на ранньому етапі',
                                },
                                {
                                    id: 4,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Інтеграція вже існуючих рішень',
                                },
                                {
                                    id: 5,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Хмарні рішення',
                                },
                                {
                                    id: 6,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Розробка додатків',
                                },
                                {
                                    id: 7,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Документація та підтримка',
                                },
                                {
                                    id: 8,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Професійна команда',
                                },
                                {
                                    id: 9,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Прозорі умови та терміни',
                                },
                                {
                                    id: 10,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Орієнтація на успіх',
                                },
                                {
                                    id: 11,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Креативність',
                                },
                            ]
                        }
                    ]
                },
                {
                    id: 2,
                    icon: 'bx bxs-calendar-check',
                    name: 'Підтримка',
                    items: [
                      {
                        id: 2,
                        title: 'Пітримка сайту',
                        price: '250',
                        period: 'monthly',
                        btnText: 'Try It Now',
                        btnLink: '/contact',
                        pricingFeatures: [
                          {
                            id: 1,
                            icon: 'bx bxs-badge-check',
                            feature: 'Підтримка хостингу',
                          },
                          {
                            id: 2,
                            icon: 'bx bxs-badge-check',
                            feature: 'Аудит та підтримка безпеки',
                          },
                          {
                            id: 3,
                            icon: 'bx bxs-badge-check',
                            feature: 'Бекап та відновелення',
                          },
                          {
                            id: 4,
                            icon: 'bx bxs-badge-check',
                            feature: 'Email підтримка',
                          },
                          {
                            id: 5,
                            icon: 'bx bxs-badge-check',
                            feature: 'Підтримка бази даних',
                          },
                          {
                            id: 6,
                            icon: 'bx bxs-badge-check',
                            feature: 'Актуалізація ПЗ',
                          },
                          {
                            id: 7,
                            icon: 'bx bxs-badge-check',
                            feature: 'Консультація щодо нових ІТ рішень',
                          },
                          {
                            id: 8,
                            icon: 'bx bxs-badge-check',
                            feature: 'Професійна команда',
                          },
                          {
                            id: 9,
                            icon: 'bx bxs-badge-check',
                            feature: 'Онлайн підтримка',
                          },
                          {
                            id: 10,
                            icon: 'bx bxs-badge-check',
                            feature: 'Аналітика роботи сайту',
                          },
                          {
                            id: 11,
                            icon: 'bx bxs-badge-check',
                            feature: 'Додаткові севіси',
                          },
                        ]
                      },
                        {
                            id: 1,
                            title: 'AWS Cloud',
                            price: '500',
                            period: 'monthly',
                            btnText: 'Try It Now',
                            btnLink: '/contact',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підтримка хмарних рішень',
                                },
                                {
                                    id: 2,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Оновлення продуктів',
                                },
                                {
                                    id: 3,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Оптимізація вартості',
                                },
                                {
                                    id: 4,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Оптимізація ресурсів',
                                },
                                {
                                    id: 5,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Аудит та покращення безпеки',
                                },
                                {
                                    id: 6,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Міграція рішень',
                                },
                                {
                                    id: 7,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Сертифіковані спеціалісти',
                                },
                                {
                                    id: 8,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підтримка онлайн',
                                },
                                {
                                    id: 9,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Рекомендації щодо нових ІТ рішень',
                                },
                                {
                                    id: 10,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Масштабування сервісів',
                                },
                                {
                                    id: 11,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Бізнес аналітика',
                                },
                            ]
                        },

                        {
                            id: 3,
                            title: 'Бізнес підтримка',
                            price: '800',
                            period: 'monthly',
                            btnText: 'Try It Now',
                            btnLink: '/contact',
                            pricingFeatures: [
                                {
                                    id: 1,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підтримка кастомних рішень',
                                },
                                {
                                    id: 2,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'А/Б тести',
                                },
                                {
                                    id: 3,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Маркетингова стратегії продукту',
                                },
                                {
                                    id: 4,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Email розсилки',
                                },
                                {
                                    id: 5,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Messenger боти',
                                },
                                {
                                    id: 6,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Статистика та розгорнуті звіти',
                                },
                                {
                                    id: 7,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підтримка життєздатності всіх систем',
                                },
                                {
                                    id: 8,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Аудит та покращення безпеки',
                                },
                                {
                                    id: 9,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Актуалізація систем',
                                },
                                {
                                    id: 10,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Підтримка рекламних кампаній',
                                },
                                {
                                    id: 11,
                                    icon: 'bx bxs-badge-check',
                                    feature: 'Онлайн підтримка',
                                },
                            ]
                        }
                    ]
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>
