<template>
    <div class="funfacts-inner">
        <div class="row">
            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bx-list-check'></i>
                    <h3>250+</h3>
                    <p>{{$t('Completed Projects')}}</p>
                </div>
            </div>

            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bx-smile'></i>
                    <h3>130+</h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bx-grid-small'></i>
                    <h3>20+</h3>
                    <p>Ongoing Projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-6 col-sm-6">
                <div class="single-funfacts funfact-style-two">
                    <i class='bx bxs-award'></i>
                    <h3>10+</h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>
