<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">How we are Founded</span>
                        <h2>Take your business to the next level</h2>
                      <p>{{$t('Ми WEB Agency що розробляє комплексні продукти та рішення для бізнесу та стартапів. Ми створюємо сайти, мобільни додатки, комплексні CRM системи та інші рішення для автоматизації, оптимизації та виведення бізнесу в інтернет.')}}</p>
                      <p>{{$t('Я Іван Костюшко, засновник. Вже більше 10 років я працюю на ІТ ринку і створюю рішення для клієнтів. З багатьма стартапами ми пройшли шлях від ідеї і до успіху. За цей час я отримав великий досвід планування та розробки продуктів та роботи з командою.')}}</p>
                     <p>{{$t('Ми працюємо на успіх клієнта і використовуємо сучасні технології що найкраще підходять та будуть найефективніші для бізнес задачі.')}}</p>
                      <p>{{$t('Напишіть нам і ми з радістю розглянемо ваші задачі.')}}</p>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/img/about-img.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TakeYourBusiness'
}
</script>
