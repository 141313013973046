import { createWebHistory, createRouter } from "vue-router";

// All Pages

import AboutPage from "../components/Pages/AboutPage";
import PricingPage from "../components/Pages/PricingPage";
import TeamPage from "../components/Pages/TeamPage";
import FaqPage from "../components/Pages/FaqPage";
import ContactPage from "../components/Pages/ContactPage";
import HomePage from "@/components/Pages/HomePage.vue";
import ServicesWebPage from "@/components/Pages/ServicesWebPage.vue";
import ServicesCloudPage from "@/components/Pages/ServicesCloudPage.vue";
import BootCampPage from "@/components/Pages/BootCampPage.vue";


const routes = [
  { path: "/", component: HomePage },
  { path: "/about", component: AboutPage },
  { path: "/pricing", component: PricingPage },
  { path: "/web-service", component: ServicesWebPage },
  { path: "/cloud-service", component: ServicesCloudPage },
  { path: "/team", component: TeamPage },
  { path: "/faq", component: FaqPage },
  { path: "/contact", component: ContactPage },
  { path: "/boot-camp", component: BootCampPage},

];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
