<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="striki-nav">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/logo.png" alt="logo">
                    </router-link>

                    <div 
                        class="navbar-toggler"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }" is-nav>
                        <ul class="navbar-nav">
                            <li class="nav-item">

                              <router-link to="/" class="nav-link" exact>
                                {{$t('Головна')}}
                              </router-link>

                            </li>

                            <li class="nav-item">
                                <router-link to="/about" class="nav-link">{{$t('Про нас')}}</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/pricing" class="nav-link">{{$t('Ціни')}}</router-link>
                            </li>

                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                  Services <i class='bx bx-chevron-down'></i>
                                </a>
                              <ul class="dropdown-menu">
                                <li class="nav-item">
                                  <router-link to="/web-service" class="nav-link">Web Services</router-link>
                                </li>

                                <li class="nav-item">
                                  <router-link to="/cloud-service" class="nav-link">Cloud Services</router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link to="/boot-camp" class="nav-link">Boot Camp</router-link>
                                </li>
                              </ul>
                            </li>

<!--                          <li class="nav-item">
                            <router-link to="/team" class="nav-link">Team</router-link>
                          </li>-->

                          <li class="nav-item">
                            <router-link to="/faq" class="nav-link">FAQ</router-link>
                          </li>

                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">{{ $t('Контакти') }}</router-link>
                            </li>
                        </ul>

                        <div class="others-options">
                            <router-link to="/contact" class="default-btn">
                                <i class="bx bxs-hot"></i>Get quote<span></span>
                            </router-link>
<!--                            <router-link to="/log-in" class="optional-btn">
                                <i class="bx bx-log-in"></i>Log In<span></span>
                            </router-link>-->
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>
                
                <div class="container">
                    <div class="option-inner">
                        <div class="others-options">
                            <router-link to="/contact" class="default-btn">
                                <i class="bx bxs-hot"></i>Get Started<span></span>
                            </router-link>
<!--                            <router-link to="/log-in" class="optional-btn">
                                <i class="bx bx-log-in"></i>Log In<span></span>
                            </router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
}
</script>
