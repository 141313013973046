<template>
    <div class="team-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('Знайомтеся з нашою командою') }}</h2>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/ivan.jpg" alt="image">

                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Ivan Kostiushko</h3>
                            <span>WEB Developer & Founder</span>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/lera.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Valeria Lebedeva</h3>
                            <span>Mobile Developer</span>
                        </div>
                    </div>
                </div>
                
<!--                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/3.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                            <h3>Bohdan Bovsunovskiy</h3>
                            <span>UI/UIX Graphic Designer</span>
                        </div>
                    </div>
                </div>-->
                
                <div class="col-lg-3 col-sm-6">
                    <div class="single-team-box">
                        <div class="image">
                            <img src="../../assets/img/team-image/4.jpg" alt="image">
                            
                            <ul class="social">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            </ul>
                        </div>

                        <div class="content">
                          <h3>Bohdan Bovsunovskiy</h3>
                          <span>UI/UIX Graphic Designer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReadyToHelpYou'
}
</script>
