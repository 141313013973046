<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle pageTitle="Cloud IT solutions for business and startups" pageDesc="Our Services" />
        <CloudServicedetails />

        <OurLovingClients />
        <FooterStyleOne />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo';
import PageTitle from '../Common/PageTitle';
import OurLovingClients from '../Common/OurLovingClients'
import FooterStyleOne from '../Layouts/FooterStyleOne'
import CloudServicedetails from "@/components/SingleService/CloudServicedetails.vue";

export default {
    components: {
      CloudServicedetails,
        NavbarStyleTwo,
        PageTitle,

        OurLovingClients,
        FooterStyleOne,
    }
};
</script>
