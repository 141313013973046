<template>
    <div class="service-details ptb-100">
        <div class="container">
            <div class="service-details-image">
                <img src="../../assets/img/services-image/web_service.jpg" alt="image">
            </div>

            <div class="service-details-content">
                <h3>{{$t('Ми розробляємо веб рішення - сайти та додатки')}}</h3>
    
                <p>{{$t('Веб рішення - це найкраший спосіб залучити клієнтів за межами вашого регіону чи навіть по всьому світу. Ви зможете з легкістю залучити нових клієнтів чи аудиторію з будь-якого місця де є інтернет.')}}</p>

                <h4>{{$t('Робзробка сайтів та інтернет магазинів')}}</h4>
                <p>{{$t('В наш час сайт компанії який є її визітівкою, розповідає про неї клієнту та пропонує сервіси це необхідність для будь-якого бізнесу. Наша професійна команда може визначити ключові терміни та ефективно представити вашу компанію в інтернеті за допомогою ефективного і персоналізованого вебсайту.')}}</p>
                <p>{{$t('Інтенет магазин - найефективніший вид комерційної діяльності. Якщо ви ще навіть знаходитися на стації ідеї - ми допоможемо вам розробити інтернет маркетингову стратегію та зможемо супровождувати запуск інтернет магазину чи продукту в інтернеті для досягнення максимальної ефективності.')}}</p>

                <h4>{{$t('Розробка мобільних додатків')}}</h4>
                <p>{{$t('Майже 90 відсотків користувачів інтернету використовують мобільні телефони, тому мобільні додатки дуже важливий інструмент січасного бізнесу. Наші досвідчена команда розробників реалізує єфективні додатки з необхідним функціоналом саме вашому бізнесу. Ми використовуємо перевірені та сучасні рішення.')}}</p>


<!--                <div class="service-details-info">
                    <div class="single-info-box">
                        <h4>Client</h4>
                        <span>James Anderson</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Category</h4>
                        <span>Saas, Marketing</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Date</h4>
                        <span>February 28, 2022</span>
                    </div>

                    <div class="single-info-box">
                        <h4>Share</h4>
                        <ul class="social">
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="#">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="single-info-box">
                        <a class="default-btn" href="#">Live Preview</a>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Servicedetails'
}
</script>
