<template>
    <div>
        <NavbarStyleTwo />
        <PageTitle pageTitle="About" pageDesc="The ArtOfSolution Story" />
        <TakeYourBusiness />
        <Partner />
        <WhyChooseUs />
        <ReadyToHelpYou class="pt-0" />
        <WhatsOurClients />
        <FreeTrial />
        <FooterStyleOne />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo';
import PageTitle from '../Common/PageTitle';
import TakeYourBusiness from '../About/TakeYourBusiness'
import Partner from '../Common/Partner'
import WhyChooseUs from '../Common/WhyChooseUs'
import ReadyToHelpYou from '../Common/ReadyToHelpYou'
import WhatsOurClients from '../HomeTwo/WhatsOurClients'
import FreeTrial from '../Common/FreeTrial'
import FooterStyleOne from '../Layouts/FooterStyleOne'

export default {
    components: {
        NavbarStyleTwo,
        PageTitle,
        TakeYourBusiness,
        Partner,
        WhyChooseUs,
        ReadyToHelpYou,
        WhatsOurClients,
        FreeTrial,
        FooterStyleOne,
    }
};
</script>
